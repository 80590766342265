.header-title {
	font-size: clamp(3rem, 5vw, 4rem);
	line-height: 1.5;
	font-weight: 700;
    /* letter-spacing: 0.1em; */
}

.header-text{
	font-size: clamp(0.1rem, 5vw, 0.5rem);
	line-height: 1.5;
	font-weight: 500;
}