@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap');

/* @font-face {
	font-family: 'Satoshi';
	src: url('../src/fonts/Satoshi-Regular.woff2') format('woff2'),
		url('../src/fonts/Satoshi-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
} */

/* 
@font-face {
    font-family: 'Satoshi';
    src: url('path-to-your-font/Satoshi-Regular.woff2') format('woff2'),
         url('path-to-your-font/Satoshi-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

:root {
	--color-brand-accent: #7c54f7;
	--button-text-color: linear-gradient(166deg, #FEFEFE -9.67%, #CAC9CD 79.86%);

	/** Light Mode */
	--color-text-black: #1c1c1c;
	--color-text-gray: #363636;
	--color-background-light: #f1f1f1;

	/** Dark Mode */
	--color-text-white: #f1f1f1;
	--color-bacground-dark: #191818;

	/** Font Clamp */
	--font-clamp-16-24: clamp(16px, 2.5vw, 24px);
	--font-clamp-14-16: clamp(14px, 2.5vw, 16px);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	background-color: var(--color-background-light);
}

body {
	margin: 0;
	font-family: 'Satoshi', sans-serif;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dark-text {
	color: var(--color-text-black);
}

.word-circled {
	background-image: url(/src/icons/circle.svg);
	overflow: visible;
	display: inline-block;
}

/* * NAVBAR */
.navbar {
	background-color: var(--color-background-light);
}

/* * TITLE TEXT */
.title-heading {
	font-size: clamp(2rem, 5vw, 4rem);
	line-height: 1.5;
	font-weight: 700;
	text-transform: uppercase;
}

/* * BUTTOM */
.buton-shadow {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: #6bf754;
	filter: drop-shadow(0 0 8px 0 rgba(0, 0, 0, 0.5), 0 0 16px 0 rgba(0, 0, 0, 0.5));
}

/* * OUR WORKS */

/* * HOW WE DO */
.bg-light {
	background-color: var(--color-background-light);
}

/* * SCROLLBAR * */
.scrollbar-hide::-webkit-scrollbar {
	display: none;
}
* ::-webkit-scrollbar {
	display: none;
}
/* For IE, Edge and Firefox */
.scrollbar-hide {
	/* -ms-overflow-style: none; IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* ! TESTING */
.test {
	background-color: #e8b5b560;
	border: 1px solid black;
}
.test-2 {
	background-color: #b5e8c356;
	border: 1px solid black;
}
.test-3 {
	background-color: #b5b8e856;
	border: 1px solid black;
}

@media (max-width: 1024px) {
	.word-circled {
		background-image: url(/src/icons/circle-sm.svg);
		overflow: visible;
		display: inline-block;
	}
}
